import React from 'react';
import styled from 'styled-components';
import logo from '../images/logo.svg';

const Logo = styled.h1`
  width: 300px;
  height: 115px;
  margin: 0 auto 3rem;
  background: center / contain no-repeat url(${logo});
  text-indent: -9999em;

  @media (min-width: 768px) {
    width: 380px;
    height: 146px;
    margin: 0 4rem 3rem 0;
  }
`;

export default Logo;
