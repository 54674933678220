import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-feather/dist/icons/phone';

const Button = ({ href, text }) => (
  <a
    className="px-8 py-4 no-underline bg-red text-white shadow hover:shadow-lg rounded-full font-brand text-3xl uppercase"
    style={{ paddingTop: '1.45rem' }}
    href={href}
  >
    <Icon className="mr-3" />
    {text}
  </a>
);

Button.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default Button;
