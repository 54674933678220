import React from 'react';
import Icon from 'react-feather/dist/icons/shield';

const Coverage = () => (
  <div className="flex items-center px-8 py-4 bg-white text-red text-xl subpixel-antialiased	rounded">
    <Icon className="mr-3" size={96} />
    <div className="leading-tight font-bold">
      Covered by various Medicaid and Commercial Plans in Louisiana
    </div>
  </div>
);

export default Coverage;
