import React from 'react';
import Coverage from './Coverage';

const Details = () => (
  <div className="max-w-measure" style={{ marginBottom: '6rem' }}>
    <p className="text-2xl leading-normal mb-8">
      Ready Responders can treat many common conditions, including: cold and flu
      symptoms, sore throat, back/joint pain, headaches, sinus problems,
      allergies, strains/sprains and much more!
    </p>

    <Coverage />

    <p className="text-2xl leading-normal mt-8">
      <strong>
        Ready Responders does not replace 911 for life-threatening emergencies.
        If you are experiencing a medical emergency dial 911.
      </strong>
    </p>
  </div>
);

export default Details;
