import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ num, title, children }) => (
  <div className="flex mb-8">
    <div className="mr-6 text-3xl">{num}</div>
    <div>
      <h2 className="mb-1 font-brand font-normal antialiased text-3xl">
        {title}
      </h2>
      <p className="max-w-measure text-2xl leading-tight">{children}</p>
    </div>
  </div>
);

Step.propTypes = {
  num: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Step;
