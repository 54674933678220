import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import logo from '../images/logo-horizontal.svg';

const Logo = styled.a`
  display: block;
  width: 207px;
  height: 65px;
  background: center / contain no-repeat url(${logo});
  text-indent: -9999em;
`;

const Footer = () => (
  <div className="bg-black">
    <div className="container mx-auto px-8 lg:flex justify-between items-center text-white antialiased">
      <p className="my-8">
        Questions? Call{' '}
        <a
          className="text-white font-bold no-underline opacity-50 hover:opacity-25"
          href="tel:+15042917970"
        >
          (504) 291-7970
        </a>
      </p>
      <Logo
        className="my-8 hover:opacity-50"
        href="https://www.readyresponders.com/"
      >
        Ready Responders
      </Logo>
    </div>
  </div>
);

export default Footer;
