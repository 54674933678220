import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import Button from './Button';
import hero from '../images/hero.jpg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background: center / cover url(${hero});
`;

const Tagline = styled.div`
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 3rem;
    margin-top: 5px;
  }
`;

const Header = () => (
  <Wrapper>
    <div className="lg:flex justify-center px-8">
      <Logo>Ready Responders</Logo>
      <div className="md:w-3/5 lg:w-1/3">
        <Tagline className="mb-3 text-center md:text-left text-white antialiased font-brand">
          Let us bring the doctor to you.
        </Tagline>
        <div className="flex justify-center md:block pt-8">
          <Button href="tel:504-291-7970" text="Call now" />
        </div>
      </div>
    </div>
  </Wrapper>
);

export default Header;
