import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 6rem;

  @media (min-width: 768px) {
    margin-top: 8rem;
  }
`;

const Container = ({ children }) => (
  <Wrapper className="container mx-auto px-8 lg:flex text-white antialiased">
    {children}
  </Wrapper>
);

export default Container;
