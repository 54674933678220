import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Container from './Container';
import Steps from './Steps';
import Coverage from './Coverage';
import Details from './Details';
import Footer from './Footer';

const App = () => (
  <React.Fragment>
    <Header />

    <Container>
      <p className="text-5xl font-brand max-w-md">
        On demand, non-emergency medical care. Right where you are.
      </p>
    </Container>

    <Container>
      <div className="lg:w-3/5">
        <Steps />
      </div>

      <div className="lg:w-2/5">
        <Details />
      </div>
    </Container>

    <Footer />
  </React.Fragment>
);

export default App;
