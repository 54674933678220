import React from 'react';
import styled from 'styled-components';
import Step from './Step';

const Steps = () => (
  <ol className="list-reset" style={{ marginBottom: '6rem' }}>
    <li>
      <Step num="1" title="Call Us">
        Simply dial 211 and press 9 for Ready Responders.
      </Step>
    </li>

    <li>
      <Step num="2" title="Explain Symptoms">
        Let us know how you feel. We assess your symptoms over the phone to make
        sure the right care is on its way to you.
      </Step>
    </li>

    <li>
      <Step num="3" title="Relax Comfortably">
        There’s no need to go anywhere. After speaking with you, we send a
        medical professional to meet you, wherever you are.
      </Step>
    </li>

    <li>
      <Step num="4" title="Get Care">
        Our medical professional will get you access to a doctor via video chat
        who can evaluate and treat you. If you require any further attention, we
        can refer you to the next point of care.
      </Step>
    </li>
  </ol>
);

export default Steps;
